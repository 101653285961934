import React, { useCallback, useContext, useEffect, useState } from "react";
import "../../index.css";
import styled from "@emotion/styled";
import { BankWrapper } from "../../components/BankWrapper";
import { BankNavigation } from "../../components/BankNavigation";
import { BankAccountNavigation } from "../../components/BankAccountNavigation";
import {
  ErrorText,
  InputRow,
  SBNavOffsetContainer,
  StyledCard,
} from "../../styled";
import {
  StateContext,
  StateContextProvider,
} from "../../contexts/StateContext";
import { Button, TextField, Typography } from "@mui/material";

const fieldRows = [
  [
    {
      key: "firstName",
      label: "First Name",
      required: true,
    },
    {
      key: "middleName",
      label: "Middle Name",
    },
    {
      key: "lastName",
      label: "Last Name",
      required: true,
    },
  ],
  [
    {
      key: "ssn",
      label: "SSN",
      required: true,
      type: "password",
    },
    {
      key: "phone",
      label: "Phone Number",
      required: true,
      type: "tel",
    },
    {
      key: "email",
      label: "Email",
      required: true,
      type: "email",
    },
  ],
  [
    {
      key: "street1",
      label: "Street 1",
      required: true,
    },
    {
      key: "street2",
      label: "Street 2",
    },
    {
      key: "city",
      label: "City",
      required: true,
    },
  ],
  [
    {
      key: "region",
      label: "State",
      required: true,
    },
    {
      key: "country",
      label: "Country",
      required: true,
    },
    {
      key: "postal_code",
      label: "Zip Code",
      required: true,
    },
  ],
];

const SettingsCard = styled(StyledCard)`
  width: 100%;
  margin: 1rem;
  align-self: start;

  > * {
    margin-bottom: 1rem;
  }
`;

function BaseComponent() {
  const { state, setState } = useContext(StateContext);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setState({
      firstName: "John",
      middleName: "Allen",
      lastName: "Doe",
      ssn: "123456789",
      birthday: "1980-02-24",
      phone: "5551239876",
      email: "johndoe1@email.com",
      pw: "password123",
      pwVerify: "password123",
      street1: "600 Montgomery St",
      street2: "Suite 600",
      city: "San Francisco",
      region: "California",
      country: "United States",
      postal_code: "94111",
    });
  }, [setState]);

  const onChange = (field) => (event) => {
    event.persist();
    setState({ ...state, [field]: event.target.value });
  };

  const onSave = useCallback(() => {
    fetch(`/api/v1/personal_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state),
    })
      .then((response) => {
        if (response.ok) {
          setSuccess("Information saved.");
        } else {
          throw new Error();
        }
      })
      .catch(() => setError("Failed to update details."));
  }, [state]);

  return (
    <BankWrapper>
      <BankNavigation />
      <SBNavOffsetContainer
        style={{
          padding: 0,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <BankAccountNavigation />
        <SettingsCard>
          <Typography variant="h4" style={{ marginBottom: "2rem" }}>
            Personal Information
          </Typography>
          {fieldRows.map((fields) => (
            <InputRow>
              {fields.map((field) => (
                <TextField
                  {...field}
                  fullWidth
                  value={state[field.key]}
                  onChange={onChange(field.key)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ))}
            </InputRow>
          ))}
          <InputRow style={{ alignItems: "center" }}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={onSave}
            >
              Save
            </Button>
            {success ? (
              <Typography variant="body1" style={{ marginLeft: "1rem" }}>
                {success}
              </Typography>
            ) : null}
            {error ? <ErrorText>{error}</ErrorText> : null}
          </InputRow>
        </SettingsCard>
      </SBNavOffsetContainer>
    </BankWrapper>
  );
}

export default function Component() {
  return (
    <StateContextProvider>
      <BaseComponent />
    </StateContextProvider>
  );
}
